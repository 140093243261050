import castle from './castle-white.png';
import './App.css';
import Snowfall from 'react-snowfall';
import FadeIn from 'react-fade-in';

function App() {
  return (
    <div className="App">
      <Snowfall />
      <FadeIn transitionDuration="1000" delay="500">
        <img src={castle} className="winterfell-castle" />
      </FadeIn>
    </div>
  );
}

export default App;
